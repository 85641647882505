var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppHeaderDropdown",
    { attrs: { right: "", "no-caret": "" } },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "float-right position-relative" }, [
          _c(
            "p",
            {
              staticClass:
                "text-left pb-0 mb-0 d-none d-md-block ml-2 mr-5 font-weight-bold h5"
            },
            [_vm._v(_vm._s(_vm.account_name))]
          ),
          _c("i", {
            staticClass: "d-none d-md-block fa fa-chevron-down dropdown-icon"
          }),
          _c(
            "p",
            { staticClass: "d-none d-md-block mb-0 pb-0 ml-2 mr-5 text-left" },
            [_vm._v("(Manager)")]
          )
        ]),
        _vm.avatar
          ? _c(
              "div",
              {
                staticClass:
                  "float-right d-none d-md-block mr-2 avatar-header-logo"
              },
              [_c("img", { attrs: { src: _vm.avatar } })]
            )
          : _vm._e(),
        _vm.avatar
          ? _c(
              "div",
              { staticClass: "float-right d-md-none mr-2 avatar-header-logo" },
              [
                _c("img", {
                  staticClass: "img-avatar",
                  attrs: { src: "img/avatars/6.jpg" }
                })
              ]
            )
          : _c("div", { staticClass: "float-right mr-2" }, [
              _c("img", {
                staticClass: "img-avatar",
                attrs: { src: "img/avatars/6.jpg" }
              })
            ])
      ]),
      _c(
        "template",
        { slot: "dropdown" },
        [
          _c(
            "b-dropdown-header",
            { staticClass: "text-center", attrs: { tag: "div" } },
            [_c("strong", [_vm._v("Account")])]
          ),
          _c(
            "b-dropdown-item",
            { attrs: { to: { path: "/manager/profile" } } },
            [_c("i", { staticClass: "fa fa-user" }), _vm._v(" Profile")]
          ),
          _c(
            "b-dropdown-item",
            { attrs: { to: { path: "/manager/password" } } },
            [_c("i", { staticClass: "fa fa-lock" }), _vm._v(" Password")]
          ),
          _c(
            "b-dropdown-item",
            {
              on: {
                click: function($event) {
                  return _vm.logout()
                }
              }
            },
            [_c("i", { staticClass: "fa fa-sign-out" }), _vm._v(" Logout")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }