export default {
  items: [
    {
      name: 'Invoice Tools',
      url: '/collapse-default',
      icon: 'fas fa-tools',
      children:
      [
        {
          name: 'Invoice Search',
          url: '/manager/',
          icon: 'fa fa-search'
        },
        {
          name: 'Master Invoice',
          url: '/manager/master-invoice',
          icon: 'fa fa-file-chart-line',
        },
        {
          name: 'Investigation Table',
          icon: 'fa fa-table',
          url: '/manager/investigation-table',
        },
      ]
    },

    {
      name: 'Projects',
      icon: 'fa fa-folder',
      url: '/manager/projects',
    },
    {
      name: 'Project Managers',
      icon: 'fa fa-user',
      url: '/manager/pm',
    },
    {
      name: 'Cost Center',
      icon: 'fa fa-container-storage',
      url: '/manager/cost-center',
    },
    {
      name: 'Service Estimates',
      url: '/manager/service-estimates',
      icon: 'fa fa-edit',
    },
    {
      name: 'Cost Code Buckets',
      url: '/manager/buckets',
      icon: 'fa fa-fill',
    },
    {
      name: 'Charts',
      url: '/collapse-default',
      icon: 'fa fa-chart-line',
      children: [
        {
          name: 'Budget VS Actuals',
          url: '/manager/chart-1',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Actual Well Cost',
          url: '/manager/chart-2',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Actual Project Cost',
          url: '/manager/chart-3',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Invoice Chart',
          url: '/manager/invoice-chart',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Bucket Chart',
          url: '/manager/chart-buckets',
          icon: 'fa fa-chart-bar',
        },
      ]
    },
  ],
}
