<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <Logo></Logo>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <TopNavBar/>
      <b-navbar-nav class="ml-auto">
        <ManagerDropdownAccnt/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <flash-message autoHide variant="success"></flash-message>
        </div>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <!-- <div class="">
        <span class="mr-1">Need Help?</span>
        <router-link :to="{ name: 'Support'}">Support</router-link>
      </div> -->
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://rtnest.ca">RTNest Inc. &copy; {{CopyrightDate}}</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from '@/_manager'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter } from '@coreui/vue'
import TopNavBar from './TopNavBar'
import Breadcrumb from './BreadCrumbCustom'
import ManagerDropdownAccnt from './ManagerDropdownAccnt'
import Logo from './Logo'
export default {
  name: 'ManagerContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Logo,
    Breadcrumb,
    ManagerDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    TopNavBar
  },
  data () {
    return {
      nav: [],
      CopyrightDate:null
    }
  },
  beforeCreate() {
    if(this.$session.get('user') !== 'manager'){
      this.$session.destroy()
      this.flash({ message: 'Unauthorized!', variant: 'danger' });
      this.$router.push('/')
    }
  },
  mounted() {
    this.nav = nav.items;
 this.CopyrightDate = new Date().getFullYear();
    this.$http.get('/manager/get/profile')
    .then(response => {
      if(response.data.authority === 1){
        this.nav.push({
          name: 'Budget Table',
          url: '/manager/budget',
          icon: 'fa fa-dollar-sign',
        },{
          name: 'Authority Levels',
          url: '/manager/authority',
          icon: 'fa fa-sort',
        })
      }
      this.$session.set('level', response.data.authority);
    })
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  }
}
</script>
