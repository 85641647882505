<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <div class='float-right position-relative'>
        <p class="text-left pb-0 mb-0 d-none d-md-block ml-2 mr-5 font-weight-bold h5">{{account_name}}</p>
        <i class="d-none d-md-block fa fa-chevron-down dropdown-icon"></i>
        <p class="d-none d-md-block mb-0 pb-0 ml-2 mr-5 text-left">(Manager)</p>
      </div>
      <div v-if="avatar" class="float-right d-none d-md-block mr-2 avatar-header-logo">
        <img v-bind:src="avatar" />
      </div>
      <div v-if="avatar" class="float-right d-md-none mr-2 avatar-header-logo">
        <img
          src="img/avatars/6.jpg"
          class="img-avatar"
        />
      </div>
      <div v-else class='float-right mr-2'><img
        src="img/avatars/6.jpg"
        class="img-avatar"
      /></div>
    </template>

    <template slot="dropdown">
      <b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>
      <b-dropdown-item :to="{ path: '/manager/profile' }"><i class="fa fa-user" /> Profile</b-dropdown-item>
      <b-dropdown-item :to="{ path: '/manager/password' }"><i class="fa fa-lock" /> Password</b-dropdown-item>
      <b-dropdown-item v-on:click="logout()"><i class="fa fa-sign-out" /> Logout</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'ManagerDropdownAccnt',
  components: {
    AppHeaderDropdown
  },
  data: () => {
    return {
      itemsCount: 42,
      account_name: "",
      account_type: "",
      avatar: "",
    }
  },
  beforeCreate() {
    if(this.$session.get('user') !== 'manager'){
      this.$session.destroy();
      this.flash({ message: 'UNAUTHORIZED', variant: 'danger' });
      this.$router.push('/');
    }
  },
  mounted() {
    this.$http.get('/manager/get/profile')
    .then(response => {
      this.account_name = response.data.fname + " " + response.data.lname;
      this.account_type = this.$session.get('user');
      this.$session.set('level', response.data.authority);
      this.get_avatar(response.data.companyid);
    })
    .catch(error => {

    })
  },
  methods: {
    get_avatar(id){
      if(id != undefined){
        this.$http.get('/get/company/avatar/' + id ).then(response => {
          if(response.data.avatar) {
            this.avatar = response.data.avatar;
          }
        })
      }
    },
    logout: function () {
      this.$session.destroy()
      this.flash({ message: 'Logout Successful', variant: 'success' });
      this.$router.push('/')
    }
  }
}
</script>
